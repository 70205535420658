<template>
  <div>
    <PageHeader :title="pageTitle" :items="breadcrumb" />
    <b-card>
      <b-row>
        <b-col md="6">
          <div class="form-group">
            <label for="nomeDistribuidora">Nome da Distribuidora</label>
            <input
              type="text"
              class="form-control"
              v-model="distribuidora.nome_distribuidora"
            />
          </div>
        </b-col>
        <b-col md="6">
          <div class="form-group">
            <label for="cnpjDistribuidora">CNPJ da Distribuidora</label>
            <input
              type="text"
              class="form-control"
              v-model="distribuidora.cnpj_distribuidora"
              v-mask="'##.###.###/####-##'"
            />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <div class="form-group">
            <label for="hora_ini_ponta">Data e Hora Início Ponta</label>
            <input
              type="text"
              class="form-control"
              v-model="
                modoEditar
                  ? horaIniPontaFormatted
                  : distribuidora.hora_ini_ponta
              "
              v-mask="'##:##'"
            />
          </div>
        </b-col>
        <b-col md="6">
          <div class="form-group">
            <label for="hora_fim_ponta">Data e Hora Fim Ponta</label>
            <input
              type="text"
              class="form-control"
              v-model="
                modoEditar
                  ? horaFimPontaFormatted
                  : distribuidora.hora_fim_ponta
              "
              v-mask="'##:##'"
            />
          </div>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between">
        <div>
          <button
            class="btn btn-success"
            v-show="!modoEditar"
            @click="salvarDistribuidora"
          >
            Salvar
          </button>
          <div v-if="modoEditar" class="btn-group-edit">
            <button class="btn btn-primary" @click="atualizarDistribuidora">
              Atualizar
            </button>
          </div>
        </div>

        <div>
          <button class="btn btn-danger" @click="removerDistribuidora">
            Remover
          </button>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import PageHeader from '@/components/page-header';
import VueMask from 'v-mask';

Vue.use(VueMask);
export default {
  data() {
    return {
      distribuidora: {
        id: null,
        nome_distribuidora: '',
        cnpj_distribuidora: '',
        hora_ini_ponta: '',
        hora_fim_ponta: '',
        ativo: true
      },
      modoEditar: false,
      pageTitle: 'Cadastro Distribuidora',
      breadcrumb: [
        {
          text: 'Distribuidoras'
        },
        {
          text: 'Cadastro Distribuidora',
          active: true
        }
      ]
    };
  },
  methods: {
    salvarDistribuidora() {
      if (this.modoEditar) {
        this.distribuidora.hora_ini_ponta = this.horaIniPontaFormatted;
        this.distribuidora.hora_fim_ponta = this.horaFimPontaFormatted;
      }
      this.$router.push('/list-distribuidoras');
    },
    atualizarDistribuidora() {
      // atualizarDistribuidora(this.distribuidora);
      // Navegar para a página de listagem após atualizar
      if (this.modoEditar) {
        this.distribuidora.hora_ini_ponta = this.horaIniPontaFormatted;
        this.distribuidora.hora_fim_ponta = this.horaFimPontaFormatted;
      }
      this.$router.push('/distribuidoras');
    },
    removerDistribuidora() {
      // removerDistribuidora(this.distribuidora.id);
      // Navegar para a página de listagem após remover
      this.$router.push('/distribuidoras');
    }
  },
  created() {
    const data = this.$route.query.distribuidora;
    if (data != null) {
      try {
        this.distribuidora = JSON.parse(data);
        console.log(this.distribuidora);
      } catch (error) {
        console.error(error);
      }
    }

    if (this.distribuidora.id != null) {
      this.modoEditar = true;
    } else {
      this.modoEditar = false;

      this.distribuidora.hora_ini_ponta = '';
      this.distribuidora.hora_fim_ponta = '';
    }
  },
  components: {
    PageHeader
  },
  computed: {
    horaIniPontaFormatted: {
      get() {
        // Extrair apenas as horas e minutos do valor original
        const time = new Date(this.distribuidora.hora_ini_ponta);
        return time.toLocaleTimeString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit'
        });
      },
      set(newValue) {
        // Se estiver no modo de edição, atualize apenas as horas e minutos
        if (this.modoEditar) {
          const timeParts = newValue.split(':');
          const date = new Date(this.distribuidora.hora_ini_ponta);
          date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10));
          this.distribuidora.hora_ini_ponta = date.toISOString().slice(0, 16);
        } else {
          // Caso contrário, mantenha o valor original
          this.distribuidora.hora_ini_ponta = newValue;
        }
      }
    },
    horaFimPontaFormatted: {
      get() {
        // Extrair apenas as horas e minutos do valor original
        const time = new Date(this.distribuidora.hora_fim_ponta);
        return time.toLocaleTimeString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit'
        });
      },
      set(newValue) {
        // Se estiver no modo de edição, atualize apenas as horas e minutos
        if (this.modoEditar) {
          const timeParts = newValue.split(':');
          const date = new Date(this.distribuidora.hora_fim_ponta);
          date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10));
          this.distribuidora.hora_fim_ponta = date.toISOString().slice(0, 16);
        } else {
          // Caso contrário, mantenha o valor original
          this.distribuidora.hora_fim_ponta = newValue;
        }
      }
    }
  }
};
</script>

<style>
.form-group {
  padding: 0px 10px;
}

.form-control {
  width: 75%;
  margin: 0px 0px 25px 00px;
}
</style>
