var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{attrs:{"title":_vm.pageTitle,"items":_vm.breadcrumb}}),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nomeDistribuidora"}},[_vm._v("Nome da Distribuidora")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.distribuidora.nome_distribuidora),expression:"distribuidora.nome_distribuidora"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.distribuidora.nome_distribuidora)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.distribuidora, "nome_distribuidora", $event.target.value)}}})])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"cnpjDistribuidora"}},[_vm._v("CNPJ da Distribuidora")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.distribuidora.cnpj_distribuidora),expression:"distribuidora.cnpj_distribuidora"},{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.distribuidora.cnpj_distribuidora)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.distribuidora, "cnpj_distribuidora", $event.target.value)}}})])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"hora_ini_ponta"}},[_vm._v("Data e Hora Início Ponta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.modoEditar
                ? _vm.horaIniPontaFormatted
                : _vm.distribuidora.hora_ini_ponta
            ),expression:"\n              modoEditar\n                ? horaIniPontaFormatted\n                : distribuidora.hora_ini_ponta\n            "},{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
              _vm.modoEditar
                ? _vm.horaIniPontaFormatted
                : _vm.distribuidora.hora_ini_ponta
            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modoEditar
                ? _vm.horaIniPontaFormatted
                : _vm.distribuidora, "hora_ini_ponta", $event.target.value)}}})])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"hora_fim_ponta"}},[_vm._v("Data e Hora Fim Ponta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.modoEditar
                ? _vm.horaFimPontaFormatted
                : _vm.distribuidora.hora_fim_ponta
            ),expression:"\n              modoEditar\n                ? horaFimPontaFormatted\n                : distribuidora.hora_fim_ponta\n            "},{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(
              _vm.modoEditar
                ? _vm.horaFimPontaFormatted
                : _vm.distribuidora.hora_fim_ponta
            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modoEditar
                ? _vm.horaFimPontaFormatted
                : _vm.distribuidora, "hora_fim_ponta", $event.target.value)}}})])])],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.modoEditar),expression:"!modoEditar"}],staticClass:"btn btn-success",on:{"click":_vm.salvarDistribuidora}},[_vm._v(" Salvar ")]),(_vm.modoEditar)?_c('div',{staticClass:"btn-group-edit"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.atualizarDistribuidora}},[_vm._v(" Atualizar ")])]):_vm._e()]),_c('div',[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.removerDistribuidora}},[_vm._v(" Remover ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }